import React from 'react';
import Layout from "../page-components/layout/layout";
import GsHeader from "../page-components/header/gsHeader";
import WarenkorbList from "../pages-part/warenkorbList";

const WarenkorbPage = () => {
    return <Layout>
        <div className="container h-100 bg-white">

            <div className="row">
                <GsHeader>
                    <h1>Warenkorb</h1>
                </GsHeader>
            </div>

            <div className="row">
                <div className="col-12">
                    <WarenkorbList />
                </div>
            </div>

        </div>
    </Layout>;
}

export default WarenkorbPage;
