import React, {useEffect, useState} from "react";
import * as styles from "./warenkorbList.module.scss";

import TrashAltSVG from "../icons/fontawesome/light/trash-alt.svg";

import { useStoreContext } from "../provider/storeProvider";
import { gsCurrencyPipe } from "../helper/gsCurrencyPipe";
import GsButton from "../page-components/buttons/gsButton";
import JSONPretty from "react-json-pretty";

const WarenkorbList = () => {

    const storeContext = useStoreContext();

    const [ totalAmount, setTotalAmount ] = useState(null);

    const handleCheckout = () => {
        window.open(storeContext.store.checkout.webUrl)
    }

    useEffect(
        () => {
            let totalAmount = 0;

            for (const item of storeContext.store.checkout.lineItems) {
                const amount = item.variant?.price * item.quantity;
                totalAmount += amount;
            }

            setTotalAmount(totalAmount);
        },
        [ storeContext.store.checkout.lineItems ]
    )

    return <div>

        {storeContext.store.checkout.lineItems.length === 0 &&
        <h3 className={"mt-4"}>Sie haben noch kein Produkt in Ihren Warenkorb</h3>
        }

        <p className={"my-3"}>
            Die Preise sind inklusive 20% Umsatzsteuer ausgewiesen.<br />
            Der länderspezifische Bruttopreis wird im Zuge des Checkouts berechnet.
        </p>

        { storeContext.store.checkout.lineItems.map(
            item => {
                return <div className={styles.element + " my-5 d-flex"} key={item.id}>

                    <img src={item.variant?.image.src} className={"img-fluid " + styles.elementImg} />

                    <div className="ps-4">
                        <div className="d-flex align-items-center">
                            <h3>{item.title}</h3>
                            <TrashAltSVG className={"ms-2 c-pointer " + styles.deleteBtn}
                                onClick={ async () => {
                                    try {
                                        await storeContext.removeLineItem(item.id);
                                    } catch(err) {

                                    }
                                } }
                            />
                        </div>
                        { !(item.customAttributes.find(c => c.key === 'protected')?.value === 'true') &&
                        <i>{ item.variant?.title }</i>
                        }

                        {(item.customAttributes.find(c => c.key === 'protected')?.value === 'true') &&
                            <table className="tw-w-full tw-table-auto tw-border-collapse mt-3">
                                {/*<thead>*/}
                                {/*<tr className="tw-bg-gray-200">*/}
                                {/*    <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">Eigenschaft</th>*/}
                                {/*    <th className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">Wert</th>*/}
                                {/*</tr>*/}
                                {/*</thead>*/}
                                <tbody>
                                <tr>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        <i>Anzahl von Schachteln</i>
                                    </td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        {item.customAttributes.find(c => c.key === 'packages')?.value}
                                    </td>
                                </tr>
                                { item.customAttributes.find(c => c.key === 'balls')?.value > 0 &&
                                <tr>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        <i>Bälle in Summe</i>
                                    </td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        {item.customAttributes.find(c => c.key === 'balls')?.value}
                                    </td>
                                </tr>
                                }
                                <tr>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        <i>Farbe</i>
                                    </td>
                                    <td className="tw-border tw-border-gray-300 tw-px-4 tw-py-2">
                                        {item.customAttributes.find(c => c.key === 'color')?.value}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        }

                        <div className={"mt-3"}>

                            { !(item.customAttributes.find(c => c.key === 'protected')?.value === 'true') && <>
                            <div className="d-flex mb-2 align-items-center">
                                <div className={styles.qtnBtn} onClick={
                                    async () => {
                                        await storeContext.updateLineItem(item.id, item.quantity + 1);
                                    }
                                }
                                >
                                    +
                                </div>

                                { item.quantity !== 1 &&  <div className={styles.qtnBtn}
                                    onClick={
                                        async () => {
                                            if (item.quantity === 1) {
                                                return;
                                            }

                                            await storeContext.updateLineItem(item.id, item.quantity - 1);
                                        }
                                    }
                                >
                                    -
                                </div> }


                                { item.quantity !== 1 &&
                                    <p className={"ms-2"}>
                                        { item.quantity } Stück zu je { gsCurrencyPipe(item.variant?.priceV2?.amount) },
                                        <b> Gesamt: { gsCurrencyPipe(item.variant?.priceV2?.amount * item.quantity) }</b>
                                    </p>
                                }

                                {item.quantity === 1 &&
                                    <p className={"ms-2"}>1 Stück um { gsCurrencyPipe(item.variant?.priceV2?.amount * item.quantity) }</p>
                                }

                            </div>
                            </> }

                            <div className={"d-flex align-items-center mt-2 " + styles.deleteBiggerBtn}
                                 onClick={
                                     async () => {
                                         try {
                                             await storeContext.removeLineItem(item.id);
                                         } catch(err) {

                                         }
                                     }
                                }
                            >
                                <TrashAltSVG className={"me-1 c-pointer svgFill " + styles.deleteBtn} />
                                <p>Löschen</p>
                            </div>




                        </div>
                    </div>
                </div>
            }
        )}


        {storeContext.store.checkout.lineItems.length !== 0 &&

            <>

                {totalAmount > 0 &&
                    <p className={"mb-3"}>
                        <h3>Gesamtsumme</h3>
                        {gsCurrencyPipe(totalAmount)}
                    </p>
                }


                <GsButton variant={"primary"} className={"mb-5"}
                          onClick={() => handleCheckout()}>
                    Jetzt kaufen
                </GsButton>

            </>
        }

    </div>

};

export default WarenkorbList;
